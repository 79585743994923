<template>
	<TnNotificationInline
		:orientation="orientation"
		:type="notificationType"
		:title="heading"
		:size="size"
		:dark="dark"
	>
		<RichText
			:dark="dark"
			:text="text"
			:size="size"
			:checkmarks="checkmarks"
		/>
		<template #actions>
			<LinkInline
				v-if="link"
				:size="size"
				:dark="dark"
				v-bind="link"
			/>
		</template>
	</TnNotificationInline>
</template>

<script>
import LinkViewModel from "../../../../helpers/ViewModels/LinkViewModel.js";
import GizmoComponentMixin from "~/mixins/GizmoComponentMixin.js";

export default defineNuxtComponent({
	mixins: [GizmoComponentMixin],

	computed: {
		notificationType() {
			if (this.component?.content?.backgroundColor) {
				// For backwards compatibility
				switch (this.component?.content?.backgroundColor) {
					case "blue":
						return "informational";
					case "yellow":
						return "attention";
					case "gray":
					default:
						return "neutral";
				}
			}
			return this.component?.content?.notificationType;
		},
		orientation() {
			return this.component?.content?.orientation || "horizontal-responsive";
		},
		size() {
			if (["s", "m", "l"].includes(this.component?.content?.size)) {
				return this.component.content.size;
			} else {
				return "m";
			}
		},
		heading() {
			return this.component?.content?.header;
		},
		text() {
			return this.component?.content?.text;
		},
		link() {
			return this.component?.content?.link && LinkViewModel(this.component?.content?.link);
		},
		checkmarks() {
			return this.component?.content?.bulletType === "Checkmark bullets";
		},
	},
});
</script>
